import {
  login,
  signUp,
  getCurrentUser,
  getAccessToken,
  signOut,
  changePassword,
  getDevice,
  setDeviceStatusRemembered,
  setDeviceStatusNotRemembered,
  forgetDevice,
  listDevices,
  forgotPassword,
  resetPassword,
  getCognitoUserSession
} from './cognito'

const openLoading = () => {
  $('#loading-overlay').css("display", "")
}

const closeLoading = () => {
  $('#loading-overlay').css("display", "none")
}

export const loginButton = () => {
  $('.login-button').click(() => {
    const email = $('#email-input').val()
    const password = $('#password-input').val()
    const newPassword = $('#new-password-input').val()
    const confirmPassword = $('#confirm-password-input').val()
    if (newPassword != confirmPassword) {
      alert("パスワードが一致しません。再度、入力してください。")
      return
    }
    if (email == newPassword) {
      alert("無効なパスワードです。別のパスワードを入力してください。")
      return
    }
    openLoading()
    login(
      email,
      password,
      newPassword,
      () => {
        closeLoading()
        $('#login-form').hide()
        $('#new-password-form').show()
      },
      () => closeLoading()
    )
  })
}

export const signOutButton = () => {
  $('#sign-out-button').click(() => {
    openLoading()
    signOut(
      () => closeLoading()
    )
  })
}

export const changePasswordButton = () => {
  $('#change-password-button').click(() => {
    const oldPassword = $('#old-password-input').val()
    const newPassword = $('#new-password-input').val()
    const confirmPassword = $('#confirm-password-input').val()
    if (newPassword != confirmPassword) {
      alert("新しいパスワードが一致しません。再度、入力してください。")
      return
    }
    if (newPassword == oldPassword) {
      alert("新しいパスワードには、現在のパスワードと異なるものを入力してください。")
      return
    }
    const cognitoUser = getCognitoUserSession()
    const email = cognitoUser.signInUserSession.idToken.payload.email
    if (newPassword == email) {
      alert("無効なパスワードです。別のパスワードを入力してください。")
      return
    }
    openLoading()
    changePassword(
      oldPassword,
      newPassword,
      () => closeLoading()
    )
  })
}

export const forgotPasswordButton = () => {
  $('#forgot-password-button').click(() => {
    const email = $('#email-input').val()
    if (!email) {
      alert("メールアドレスを入力してください。")
      return
    }
    openLoading()
    forgotPassword(
      email,
      () => {
        closeLoading()
        $('#forgot-password-form').hide()
        $('#new-password-form').show()
      },
      () => closeLoading()
    )
  })
}

export const resetPasswordButton = () => {
  $('#reset-password-button').click(() => {
    const email = $('#email-input').val()
    const verificationCode = $('#verification-code-input').val()
    const newPassword = $('#new-password-input').val()
    const confirmPassword = $('#confirm-password-input').val()
    if (newPassword != confirmPassword) {
      alert("新しいパスワードが一致しません。再度、入力してください。")
      return
    }
    if (email == newPassword) {
      alert("無効なパスワードです。別のパスワードを入力してください。")
      return
    }
    openLoading()
    resetPassword(
      email,
      verificationCode,
      newPassword,
      () => closeLoading()
    )
  })
}