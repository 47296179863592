import $ from 'jquery'
import {
  Config,
  CognitoIdentityCredentials,
  AmazonCognitoIdentity
} from "aws-sdk"
import {
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUser,
  CognitoIdentityServiceProvider,
} from "amazon-cognito-identity-js"

import appConfig from "./config"


Config.region = appConfig.Region
Config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: appConfig.IdentityPoolId
})

const userPool = new CognitoUserPool({
  UserPoolId: appConfig.UserPoolId,
  ClientId: appConfig.ClientId,
})


export const login = (
  email,
  password,
  newPassword = '',
  onNewPasswordRequired,
  onFailed
) => {
  var authenticationData = {
    Username: email,
    Password: password
  }
  var authenticationDetails = new AuthenticationDetails(authenticationData)
  var userData = {
    Username: email,
    Pool : userPool
  }
  var cognitoUser = new CognitoUser(userData)
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => {
      AWS.config.region = appConfig.Region
      AWS.config.credentials = new CognitoIdentityCredentials({
        IdentityPoolId : appConfig.IdentityPoolId,
        Logins : {
          [`cognito-idp.${appConfig.Region}.amazonaws.com/${appConfig.UserPoolId}`] : result.getIdToken().getJwtToken()
        }
      })
      AWS.config.credentials.refresh((error) => {
        if (error) {
          alert(error.message)
          onFailed()
        } else {
          $.ajax({
            url: "/login",
            type: 'POST',
            data: {
              authenticity_token: $("#authenticity_token").val(),
              access_token: result.getAccessToken().getJwtToken()
            }
          })
        }
      })
    },
    onFailure: (err) => {
      alert(err.message)
      onFailed()
    },
    newPasswordRequired: function(userAttributes, requiredAttributes) {
      if (newPassword) {
        cognitoUser.completeNewPasswordChallenge(newPassword, {}, this)
      } else {
        onNewPasswordRequired()
      }
    }
  })
}

export const getCurrentUser = () => {
  const cognitoUser = userPool.getCurrentUser()

  if (cognitoUser != null) {
    console.log('you are singed in.')
  } else {
    console.log('you are not signed in')
  }
}

export const getAccessToken = () => {
  const cognitoUser = userPool.getCurrentUser()
  let accessToken = ''
  if (cognitoUser != null) {
    console.log('you are singed in.')
      cognitoUser.getSession((err, session) => {
          if (err) {
            alert(err.message)
            return
          }
          accessToken = session.getAccessToken().jwtToken
      })
  } else {
    console.log('you are not signed in')
  }
  return accessToken
}

export const signOut = (onFailed) => {
  const cognitoUser = userPool.getCurrentUser()
  if (cognitoUser != null) {
    cognitoUser.signOut()
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId : appConfig.IdentityPoolId
    })
    AWS.config.credentials.clearCachedId()
    $.ajax({
      url: "/logout",
      type: 'POST',
      data: {
        authenticity_token: $("#authenticity_token").val()
      }
    })
  } else {
    alert("すでにログアウトしています。")
    onFailed()
    $.ajax({
      url: "/logout",
      type: 'POST',
      data: {
        authenticity_token: $("#authenticity_token").val()
      }
    })
  }
}

export const changePassword = (
  oldPassword,
  newPassword,
  onFailed
) => {
  const cognitoUser = getCognitoUserSession()
  cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
    if (err) {
        alert(err.message)
        onFailed()
        return
    }
    $.ajax({
      url: "/setting/password",
      type: 'POST',
      data: {
        authenticity_token: $("#authenticity_token").val()
      }
    })
  })
}

export const forgotPassword = (email, onUserFound, onFailed) => {
  var userData = { 
    Username : email,
    Pool : userPool
  };
  var cognitoUser = new CognitoUser(userData)

  cognitoUser.forgotPassword({
    onFailure: (err) => {
      switch(err.code) {
        case "UserNotFoundException": {
          alert("入力されたメールアドレスは、ログインIDとして登録されていません。\n登録したメールアドレスを、再度入力してください。")
          break
        }
        default: {
          alert(err.message)
          break
        } 
      }
      onFailed()
    },
    inputVerificationCode() {
      onUserFound()
    }
  })
}

export const resetPassword = (email, verificationCode, newPassword, onFailed) => {
  var userData = { 
    Username : email,
    Pool : userPool
  };
  var cognitoUser = new CognitoUser(userData)

  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onFailure: (err) => {
      switch(err.code) {
        case "CodeMismatchException": {
          alert("認証コードが誤っています。再度入力してください。")
          break
        }
        default: {
          alert(err.message)
          break
        } 
      }
      onFailed()
    },
    onSuccess: (result) => {
      $.ajax({
        url: "/password",
        type: 'POST',
        data: {
          authenticity_token: $("#authenticity_token").val()
        }
      })
    }
  })
}

export const getCognitoUserSession = () => {
  const cognitoUser = userPool.getCurrentUser()
  cognitoUser.getSession((err, session) => {
    if (err) {
      alert(err.message)
      return
    }
  })
  return cognitoUser
}
